<script lang="ts">
  import { onMount } from "svelte";
  import axios from "axios";
  import "smelte/src/tailwind.css";
  import type { IRomRating } from "./IRomRating";
  import { TextField, Button } from "svelte-materialify";
  import { SyncLoader } from "svelte-loading-spinners";
  import { Slider } from "svelte-materialify";
  export let params;
  let isLoading: boolean;
  export let rom: IRomRating = {
    rumId: params.id,
    sweetness: 1,
    ester: 1,
    rating: 1,
    price: 0,
    alc: 0,
    fragranceNotes: "",
    tastingNotes: "",
  };
  const server = "https://casper-rom-api.azurewebsites.net/";
  // const server = "https://localhost:7106/";

  onMount(async () => {
    loadRum();
  });
  async function loadRum(text?: string) {
    isLoading = true;
    try {
      rom = (
        await axios.get(`${server}api/rumrating/` + params.id, {
          headers: {
            Authorization: `bearer ${localStorage.getItem("oauth")}`,
          },
        })
      ).data;
    } catch (error) {
    } finally {
      isLoading = false;
    }
  }

  async function updateRum() {
    // isLoading = true;
    await axios.post(`${server}api/rum/saverating/`, rom, {
      headers: {
        Authorization: `bearer ${localStorage.getItem("oauth")}`,
      },
    });
    // history.back();
  }
</script>

<main>
  <div class="navbar">
    <div>
      <!-- <div
        on:click={() => history.back()}
        style="position:absolute;font-size:73px;top: -39px;"
      >
        ←
      </div> -->
      <div style="padding:12px">Casper's rum</div>
    </div>
    <div style="font-size:14px">{params.name}</div>
  </div>
  {#if isLoading}
    <div style="display: flex;justify-content: center;">
      <SyncLoader size="60" color="#ffb300" unit="px" duration="1s" />
    </div>
  {/if}
  {#if !isLoading}
    <div class="field">
      <TextField
        value={rom.fragranceNotes}
        on:change={(e) => {
          rom.fragranceNotes = e.target.value;
        }}
      >
        Duftnoter
      </TextField>
    </div>
    <div class="field">
      <TextField
        value={rom.tastingNotes}
        on:change={(e) => {
          rom.tastingNotes = e.target.value;
        }}
      >
        Smagsnoter
      </TextField>
    </div>
    <div class="field">
      <Slider
        min={1}
        step={1}
        max={10}
        value={[rom.sweetness]}
        connect={[false, true]}
        on:change={(e) => {
          rom.sweetness = e.detail.value[0];
        }}
      >
        {`Sødme - ${rom.sweetness}`}
      </Slider>
    </div>
    <div class="field">
      <Slider
        min={1}
        step={1}
        max={10}
        value={[rom.ester]}
        connect={[false, true]}
        on:change={(e) => {
          rom.ester = e.detail.value[0];
        }}
      >
        {`Ester - ${rom.ester}`}
      </Slider>
    </div>
    <div class="field" style="display:flex">
      <TextField
        value={rom.alc + ""}
        on:change={(e) => {
          rom.alc = Number(e.target.value);
        }}
      >
        Alc %
      </TextField>
      <div>&nbsp;</div>
      <TextField
        value={rom.price + ""}
        on:change={(e) => {
          rom.price = Number(e.target.value);
        }}
      >
        Pris (dkr)
      </TextField>
    </div>
    {/if}
    <Button disabled={isLoading} on:click={() => updateRum()} style="width:100%">Gem og luk</Button>
</main>

<!-- {JSON.stringify(rom)} -->
<style>
  .field {
    margin: 20px;
  }
  .navbar {
    overflow: hidden;
    background-color: #fff;
    /* position: fixed; */
    top: 0;
    width: 100%;
    font-size: 20px;
    text-align: center;
  }
  main {
    text-align: center;
    width: 100vw;
  }
</style>
