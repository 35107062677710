<script lang="ts">
  import "smelte/src/tailwind.css" ;
  import { onMount } from "svelte";
  import axios from "axios";
  import { SyncLoader } from "svelte-loading-spinners";
  import type { IRom } from "./IRom";
  import SearchList from "./SearchList.svelte";

  let isLoading: boolean;
  export let list: IRom[] = [];

  const server = "https://casper-rom-api.azurewebsites.net/";
  // const server = "https://localhost:7106/";
  onMount(async () => {
    try {
    await loadRum();
      
    } catch (error) {
      // window.location.href = "/";
      
    }
  });
  async function loadRum(text?: string) {
    isLoading = true;

    list = (
      await axios.get(`${server}api/rum/search/${text || ""}`, {
        headers: {
          Authorization: `bearer ${localStorage.getItem("oauth")}`,
        },
      })
    ).data;
    isLoading = false;
  }
  let timer;
  const debounce = (value: string) => {
    clearTimeout(timer);
    timer = setTimeout(async () => {
      loadRum(value);
    }, 750);
  };
</script>

<main>
  <div class="navbar">
    <div style="padding:12px">Casper's rum</div>
    <input
      placeholder="Søg"
      style="width:100%;font-size: 18px;"
      type="text"
      on:keyup={(e) => debounce(e.currentTarget.value)}
    />
  </div>

  <div>
    <div class="listBorder">
      {#if isLoading}
        <div style="display: flex;justify-content: center;">
          <SyncLoader size="60" color="#ffb300" unit="px" duration="1s" />
        </div>
      {/if}
      {#if !isLoading}
        <SearchList {list} />
      {/if}
    </div>
  </div>
</main>

<style>
  .listBorder {
    list-style-type: none;
    padding: 0;
    padding-top: 110px;
  }
  .navbar {
    overflow: hidden;
    background-color: #fff;
    position: fixed;
    top: 0;
    width: 100%;
    font-size: 20px;
  }
  main {
    text-align: center;
    width: 100vw;
  }
  input[type="text"] {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
  }

</style>
